<template>

    <div>
        <!--탑메뉴-->
        <topbar-comp></topbar-comp>
        <left-bar-comp>
            <sports-left-bar-comp></sports-left-bar-comp>
        </left-bar-comp>

        <div class="content_section">
            <div class="content">
                <login-links-comp></login-links-comp>
                <div class="logo_panel" style="padding-top: 50px">
                    <div class="logo">
                        <img src="../../assets/images/logo/oneplay/oneplay_logo.png">
                    </div>
                    <div class="ment line">
                        스포츠 게임 <span style="color: #079e39">원플레이</span>에 오신것을 환영합니다.
                    </div>
                    <div class="ment mt10" style="font-size: 12px;color: #c4c4c3">
                        <p>회원님의 아이디와 비밀번호를 찾으실수 있습니다.</p>
                    </div>

                    <div class="terms_box" style="background-color: #343434">
                        <div class="join-container">
                            <p style="text-align: left;font-size: 10px;color: #9f9fa1;height: 28px;line-height: 28px;display: block">
                                <span style="font-size: 16px;font-weight: bold">아이디 찾기</span> 회원가입하신 전화번호로 본인인증을 하시면 아이디를 확인하실 수 있습니다.
                            </p>
                            <div class="findpwd_insertid mt5">
                                <div class="f1 mt5">
                                    <p>아이디 찾기</p>
                                </div>
                                <div class="f2 mt5">
                                    <button class="btn03 btn_check_phone" v-if="isShowFindIdBtn" @click="findId">아이디보기
                                    </button>
                                    <button class="btn03 btn_check_phone" @click="authphone(2)">휴대폰인증</button>
                                    <button class="btn03 btn_check_phone text-primary">아이핀인증</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="terms_box" style="background-color: #343434">
                        <div class="join-container">
                            <p style="text-align: left;font-size: 10px;color: #9f9fa1;height: 28px;line-height: 28px;display: block">
                                <span style="font-size: 16px;font-weight: bold">비밀번호 찾기</span>  아이디를 입력후 본인인증을 하시면 임시비밀번호를 확인하실 수 있습니다.
                            </p>
                            <div class="findpwd_insertid mt5">
                                <div class="f1 mt5">
                                    <input type="text" style="height: 28px;color: #0c0e0e;text-align: center;" v-model="username"
                                           placeholder="아이디를 입력하세요">
                                </div>
                                <div class="f2 mt5">
                                    <button class="btn03 btn_check_phone" v-if="isShowFindPwdBtn" @click="findPwd">비밀번호보기</button>
                                    <button class="btn03 btn_check_phone" @click="authphone(3)">휴대폰인증</button>
                                    <button class="btn03 btn_check_phone text-primary">아이핀인증</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 본인인증 서비스 팝업을 호출하기 위해서는 다음과 같은 form이 필요합니다. -->
                    <form name="form_chk1" method="post">
                        <input type="hidden" name="m" value="checkplusService"><!-- 필수 데이타로, 누락하시면 안됩니다. -->
                        <input type="hidden" name="EncodeData" :value="sencData1">
                    </form>
                    <!-- 본인인증 서비스 팝업을 호출하기 위해서는 다음과 같은 form이 필요합니다. -->
                    <form name="form_chk2" method="post">
                        <input type="hidden" name="m" value="checkplusService"><!-- 필수 데이타로, 누락하시면 안됩니다. -->
                        <input type="hidden" name="EncodeData" :value="sencData2">
                    </form>
                </div>

            </div>
        </div>

        <!-- right 메뉴 -->
        <right-bar-comp>
            <right-bar-banner-comp slot="rightBanner"></right-bar-banner-comp>
        </right-bar-comp>
        <foot-comp></foot-comp>

    </div>
</template>

<script>
    import {
        checkUserName,
        checkNickName,
        getVerificationCode,
        register,
        getSencdata,
        findId, findPwd
    } from "../../network/userRequest";
    import {loginRegisterMixin} from "../../common/mixin";
    import TopbarComp from "../../components/TopbarComp";
    import LeftBarComp from "../../components/LeftBarComp";
    import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";
    import LoginLinksComp from "../../components/links/LoginLinksComp";
    import RightBarComp from "../../components/RightBarComp";
    import RightBarBannerComp from "../../components/RightBarBannerComp";
    import FootComp from "../../components/FootComp";

    export default {
        name: "findpwd",
        components: {
            FootComp,
            RightBarBannerComp, RightBarComp, LoginLinksComp, SportsLeftBarComp, LeftBarComp, TopbarComp
        },
        data() {
            return {
                sencData1: null,
                sencData2: null,
                isShowFindIdBtn: false,
                isShowFindPwdBtn: false,
                username:null
            }
        },
        methods: {
            authphone(type) {
                if(type ===2){
                    this.fnPopup1()
                    this.isShowFindIdBtn = true
                }
                if(type === 3){
                    this.fnPopup2()
                    this.isShowFindPwdBtn = true
                }

            },
            fnPopup1() {
                window.open('', 'popupChk', 'width=375, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no');
                document.form_chk1.action = "https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb";
                document.form_chk1.target = "popupChk";
                document.form_chk1.submit();
            },
            fnPopup2() {
                window.open('', 'popupChk', 'width=375, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no');
                document.form_chk2.action = "https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb";
                document.form_chk2.target = "popupChk";
                document.form_chk2.submit();
            },
            findId(){
                findId().then(res=>{
                    if(res.data.success){
                        let id  =(res.data.data)
                        this.$swal({
                            title: '회원님 아이디는 '+id+' 입니다',
                            type: 'success',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    } else {
                        this.$swal({
                            title: res.data.msg,
                            type: 'error',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    }
                })
            },
            findPwd(){
                console.log(this.username)
                if(this.username == null){
                    this.$swal({
                        title: '아이디를 입력하세요',
                        type: 'error',
                        showCancelButton: false,
                        showConfirmButton: true
                    })
                    return false;
                }
                findPwd(this.username).then(res=>{
                    if(res.data.success){
                        let pwd  =(res.data.data)
                        let title ='회원님 임시비밀번호는 '+pwd+' 입니다.'
                        title += "\r\n " + "로그인후 내정보수정에서 새비밀번호를 등록바랍니다."
                        this.$swal({
                            title: title,
                            type: 'success',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    } else {
                        this.$swal({
                            title: res.data.msg,
                            type: 'error',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    }
                })
            },

        },
        created() {
            getSencdata(2).then(res => {
                if (res.data.success) {
                    this.sencData1 = res.data.data
                } else {
                    this.$swal({
                        title: res.data.msg,
                        type: 'error',
                        showCancelButton: false,
                        showConfirmButton: true
                    })
                }
            })
            getSencdata(3).then(res => {
                if (res.data.success) {
                    this.sencData2 = res.data.data
                    console.log(this.sencData2)
                } else {
                    this.$swal({
                        title: res.data.msg,
                        type: 'error',
                        showCancelButton: false,
                        showConfirmButton: true
                    })
                }
            })
        }
    }
</script>

<style scoped>
    @import url("../../assets/css/lore.css");

    .findpwd_insertid {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    @media screen and (max-width: 1024px) {
        .findpwd_insertid .f1 {
            width: 100% !important;
        }

        .findpwd_insertid .f2 {
            width: 100% !important;
        }

        .labels {
            width: 100px !important;
        }
    }
</style>